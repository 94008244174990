


import Component from 'vue-class-component';
import {Vue, Watch} from 'vue-property-decorator';
import {Validations} from 'vuelidate-property-decorators';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import {required} from 'vuelidate/lib/validators';
import EventLanguageSelector from '@/_modules/events/components/event-language-selector/event-language-selector.vue';
import _cloneDeep from 'lodash.clonedeep';
import DateTimeHelper from '@/_helpers/date-time.helper';
import {EventAccessType, TEvent} from '@/_types/event.type';
import timezone from '@/_modules/events/components/timezones.json';
import {TTimezoneInfo} from '@/_types/timezone-info.type';
import {TranslateResult} from 'vue-i18n';
import DatepickerHelper, {TMuseUIDatepickerDateTimeFormat} from '@/_helpers/datepicker.helper';
// @ts-ignore
import Statistics from '@/services/statistics.js';
import {Route} from 'vue-router';
import {NavigationGuardNext} from 'vue-router/types/router';
import {Getter} from 'vuex-class';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

import ErrorInfo from '@/_modules/error-info/error-info.vue';

type TCreateEventFake = {
  title: string;
  date_start: Date;
  date_end: Date;
  access_type: EventAccessType;
  languages: string[];
  time_region: string;
}

let prevStartDate: Date = null;

const isValidStartDateNotInPast = (startDate: Date): boolean => { // TODO: move this to helper
  if (startDate === null || startDate === undefined) {
    return true;
  }

  const currentDate = new Date().getTime() / 1000;
  const dateStart = new Date(startDate).getTime() / 1000;

  prevStartDate = startDate;

  return +currentDate < dateStart;
};

const isValidEndDateNotInPast = (endDate: Date): boolean => { // TODO: move this to helper

  if ((prevStartDate === null || prevStartDate === undefined) && (endDate === null || endDate === undefined)) {
    return true;
  }

  if (prevStartDate && endDate) {
    const _startDate: number = new Date(prevStartDate).getTime() / 1000;
    const _dateEnd: number = new Date(endDate).getTime() / 1000;

    return _dateEnd > _startDate;
  } else {
    return true;
  }
};

const beforeRouteEnter: { to: Route; from: Route; next?: NavigationGuardNext } = {to: null, from: null, next: null};

@Component({
  components: {EventLanguageSelector, ErrorInfo},
})

export default class CreateEvent extends Vue {
  @Getter('_eventStore/eventError') eventError: ApiErrorResponseData;

  @Validations()
  public readonly validations: TVuelidateRuleSet<TCreateEventFake> = {
    formData: {
      title: {
        required,
      },
      date_start: {
        required,
        isValidStartDate(date_start: Date): boolean {
          return isValidStartDateNotInPast(date_start);
        }
      },
      date_end: {
        required,
        isValidEndDate(date_end: Date): boolean {
          return isValidEndDateNotInPast(date_end);
        }
      },
      time_region: {
        required,
      }
    },
  }
  public timezone: TTimezoneInfo[] = timezone;

  public formData: TCreateEventFake = {
    title: '',
    date_start: null,
    date_end: null,
    access_type: EventAccessType.REGISTER,
    languages: ['en'],
    time_region: ''
  };
  public endDateMinimum: Date = new Date()

  public isShowStartDate: boolean = false
  public isShowEndDate: boolean = false
  public isShowTimezoneLocation: boolean = false
  public isMultipleLanguages: boolean = false
  public isSendSuccess: boolean = false
  public createdHintText: TranslateResult = ''
  public timezoneLocation: TTimezoneInfo = {} as TTimezoneInfo;
  public isSaveEventButtonDisabled: boolean = false;

  @Watch('timezoneLocation', {immediate: true})
  private onTimezoneLocationChanged(): void {
    if (this.timezoneLocation && !this.timezoneLocation.utc) {
      return;
    }
    this.formData.time_region = this.timezoneLocation.utc[0];
  }

  public beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  }

  private autoSetEndDate(val: Date): void {
    const start: number = new Date(this.formData.date_start).getTime() / 1000;
    const end: number = new Date(this.formData.date_end).getTime() / 1000;
    // minimum end date is 1 hour ahead, used in the :min-date prop
    this.endDateMinimum = new Date(this.formData.date_start.getTime() + 60 * 60 * 1000);

    // val has to be present and be a JS Date object. Simple check using getTime
    if (val && ('getTime' in val)) {
      if (!this.formData.date_end || start > end) {
        const date_end = new Date(val);
        date_end.setHours(23, 59, 59);

        // If auto-set date_end time is closer than 1 hour, move it to next day 23:59
        if (date_end.getTime() - val.getTime() < (60 * 60 * 1000)) {
          date_end.setTime(date_end.getTime() + 1000 * 60 * 60 * 24);
        }

        this.formData.date_end = date_end;
      }
    }
  }

  private checkEndDate(val: Date): void {

    // Set hours to date_start.hours + 1
    if (val.getTime() - +this.formData.date_start < 60 * 60 * 1000) {
      this.formData.date_end = new Date(this.formData.date_start.getTime() + 60 * 60 * 1000);
    }
  }

  private getCalendarDateTimeFormat(): TMuseUIDatepickerDateTimeFormat {
    return DatepickerHelper.getMuseUIDatepickerDateTimeFormat();
  }

  private firstDayOfWeek(): number {
    return DatepickerHelper.getFirstDayOfWeekNumber();
  }

  private handleEventLanguagesUpdated(eventData: string[]): void {
    this.formData.languages = _cloneDeep(eventData);
  }

  private showMultipleLanguages(event: Event): void {
    this.isMultipleLanguages = (event.target as HTMLInputElement).value === 'show';
  }

  private async createEvent(): Promise<TEvent | void> {
    const ref = localStorage.getItem('referrer_url');
    this.isSaveEventButtonDisabled = true;

    this.$v.formData.$touch();

    if (!this.formData || this.$v.formData.$pending || this.$v.formData.$invalid) {
      this.isSaveEventButtonDisabled = false;
      return;
    }

    const createData = Object.assign({}, this.formData, {
      date_start: DateTimeHelper.dateToApiDate(this.formData.date_start),
      date_end: DateTimeHelper.dateToApiDate(this.formData.date_end),
      ref: ref
    });

    const result = await this.$store.dispatch('_eventStore/createEvent', createData);
    if (result) {
      this.$v.$reset();
      this.isSendSuccess = true;
      this.createdHintText = this.$t('eventPage.create.createSuccess');

      (window as any).dataLayer.push({
        event: 'create-event-form-submit',
        formType: 'create-event'
      });

      await Statistics.createEventStat({
        eventId: result.id,
        referrerUrl: localStorage.getItem('referrer_url'),
        userId: result.creator_user_id
      }, beforeRouteEnter);

      this.$router.push({name: 'promo-page-cabinet', params: {eventId: '' + result.id}});
    } else {
      this.isSendSuccess = false;
      this.createdHintText = this.$t('eventPage.create.createError');
      this.isSaveEventButtonDisabled = false;
    }

  }
}
